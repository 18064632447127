<template>
  <div class="">
    <v-overlay
      v-show="
        guaranteeVoucherStatusRequest.value ===
          'loading-getGuaranteeVoucherById'
      "
      opacity="0.5"
    >
      <v-progress-circular
        color="grey lighten-2"
        indeterminate
      ></v-progress-circular>
    </v-overlay>

    <transition name="component-trans" mode="out-in">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
export default {
  computed: {
    guaranteeVoucherStatusRequest() {
      return this.$store.getters["GUARANTEE_VOUCHER/statusRequest"];
    }
  }
};
</script>

<style lang="scss" scoped></style>
